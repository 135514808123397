/**=====================
    19. React-Plugin CSS Start
==========================**/
.simple-list {
  .list-group-item {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}
.simple-btn {
  background-color: transparent !important;
  border: none;
  padding: 0;
  margin: 0;
}
li.list-group-item.active {
  background-color: unset;
  border-color: unset;
  color: unset;
}
img.media {
  margin-bottom: 0;
}
ul.list-group {
  display: block;
  li.list-group-item.active {
    background-color: $transparent-color;
    border-color: $transparent-color;
  }
  img {
    width: auto;
    height: auto;
  }
  i {
    margin-right: unset;
  }
}
ul.bgcolor {
  li.list-group-item.active {
    background-color: #534686 !important;
    border-color: #534686 !important;
    color: #e6e6e6 !important;
    cursor: pointer;
  }
}
// slick
.slick-page {
  .slick-slider {
    margin: -8px 0 20px;
  }
}
.slick-slider {
  margin: -7px 0 0;
  .item {
    &:focus {
      outline: none;
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: -10px;
    margin-top: 20px;
    li {
      button {
        &:before {
          font-size: 12px;
          color: var(--theme-default);
        }
      }
      &.slick-active {
        button {
          &:before {
            color: var(--theme-default);
          }
        }
      }
    }
  }
}
.product-main {
  .slick-slider {
    margin: 0;
  }
}
.widget-list .simple-list li {
  color: $white;
}
// sticky
.note {
  background-color: rgba($primary-color, 0.3);
  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}
.react-stickies-wrapper {
  .btn-close {
    &:before,
    &:after {
      background: $transparent-color !important;
    }
  }
  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }
      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }
      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }
      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }
      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }
      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}
.date-picker .cal-date-widget {
  .react-datepicker {
    border: none;
    padding: 8px 16px 0;
    width: 100%;
  }
  .react-datepicker__navigation {
    top: 20px;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: $primary-color;
  }
  .react-datepicker__navigation-icon {
    &::before {
      border-width: 2px 2px 0 0;
      width: 8px;
      height: 8px;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    height: 32px;
  }
  .react-datepicker__day--outside-month {
    color: $gray-60;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
}
.react-datepicker {
  border: 1px solid $light-semi-gray;
}
.react-datepicker__header {
  background-color: $white;
  border: none;
}
.react-datepicker__navigation {
  background-color: $primary-color;
  border-radius: 20px;
  color: $white;
  top: 13px;
  &.react-datepicker__navigation--previous {
    left: 4px;
  }
  &.react-datepicker__navigation--next {
    right: 4px;
  }
}
.react-datepicker__day--selected {
  background-color: $primary-color;
  border-radius: 10px;
  &:hover {
    background-color: $primary-color;
  }
}
.react-datepicker__navigation-icon--next {
  left: -3px;
}
.react-datepicker__navigation-icon {
  top: 0px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $primary-color;
  padding: 10px 10px 20px;
}
.react-datepicker__navigation-icon--previous {
  right: 0;
}
.react-datepicker__day-name {
  font-weight: bold;
}
.react-datepicker__week,
.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous {
  &::before {
    top: 10px;
    left: -3px;
    border-color: $white;
  }
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-popper[data-placement^="top"],
.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    margin-left: -10px;
  }
}
// tree css
.deni-react-treeview-container {
  width: 100% !important;
  .icon-and-text {
    .text {
      .text-inner {
        color: $primary-color;
        font-size: 14px;
        .treeview-item-example-text {
          vertical-align: middle;
        }
      }
    }
  }
}
.deni-react-treeview-container.classic {
  border-color: $body-color !important;
  .deni-react-treeview-item-container.classic {
    &.selected.select-row {
      background: linear-gradient(
        to bottom,
        rgba($primary-color, 0.1) 0%,
        rgba($primary-color, 0.5) 100%
      ) !important;
    }
    &:hover.select-row {
      background-color: rgba($primary-color, 0.1) !important;
      border: unset;
    }
    .checkbox {
      border-color: $primary-color !important;
    }
    .expand-button.hasChild {
      color: $primary-color !important;
    }
    .actionButton {
      margin-left: 6px;
    }
    .icon-and-text {
      &.selected {
        background: linear-gradient(
          to bottom,
          rgba($primary-color, 0.1) 0%,
          rgba($primary-color, 0.6) 100%
        ) !important;
        border: unset;
      }
      &:hover:not(.select-row) {
        background-color: rgba($primary-color, 0.1) !important;
        border: unset;
        padding-left: calc(5px - 0px);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
}
.deni-react-treeview-item-container {
  .expand-button.colapsed {
    &::before {
      height: 29px;
    }
  }
}
// tour page
.reactour__helper {
  .reactour__close {
    top: 16px;
    right: 16px;
  }
  span[data-tour-elem="badge"] {
    background-color: $primary-color;
    height: 30px;
  }
  .reactour__dot {
    &.reactour__dot--is-active {
      background-color: $primary-color;
    }
  }
}
.EasyMDEContainer{
  .CodeMirror{
    border: 1px solid $light-gray;
  }
}
// data tables 
.data-tables{
  .rdt_Table{
    .rdt_TableHead{
      .rdt_TableCol{
        .rdt_TableCol_Sortable{
          div{
            font-size: 14px;
            ~ span{
              display: none;
            }
          }
        }
      }
    }
  }
}
// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
  .rangeslider__fill {
    background-color: var(--theme-default) !important;
  }
  .rangeslider__handle {
    &:focus {
      outline: none;
    }
  }
}
.slider-vertical,
.vertical-height {
  .value {
    text-align: center;
  }
}
.rangeslider-vertical {
  .rangeslider__labels {
    margin: 0 0 0 32px !important;
  }
}
.input-range__slider {
  background: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.input-range__track--active {
  background: var(--theme-default);
}
.input-range {
  margin-bottom: 34px;
}
// dropzone
.dzu-dropzone {
  border: 2px dashed var(--theme-default) !important;
  border-radius: 10px;
  background: rgba($primary-color, 0.1);
  overflow: auto !important;
  .dzu-inputLabel {
    padding: 0 14px;
  }
  .dzu-previewImage {
    max-height: 54px;
  }
  .dz-message {
    .note {
      background-color: $transparent-color;
    }
  }
}
.dzu-inputLabel {
  color: var(--theme-default) !important;
  text-align: center;
  margin-bottom: 0;
  font-family: $font-roboto;
}
.dzu-previewContainer {
  padding: 30px !important;
  border-bottom: unset;
}
.dzu-inputLabelWithFiles {
  margin-left: 30px !important;
  background-color: var(--theme-default) !important;
  color: $white !important;
}
.dzu-submitButton {
  background-color: var(--theme-default) !important;
  &:disabled {
    background-color: #e6e6e6 !important;
  }
}
// Image Cropper
.input-cropper {
  border-radius: 4px;
  width: auto;
  ~ .ReactCrop {
    margin-top: 20px;
  }
}
.ReactCrop {
  ~ div {
    > canvas {
      margin-top: 25px;
      width: 35% !important;
      height: 35% !important;
    }
  }
}
.crop-portion {
  margin-left: 30px;
  margin-top: 30px;
  vertical-align: baseline;
}
.cropper-view-box {
  outline-color: var(--theme-default);
  outline: 1px solid var(--theme-default);
}
.cropper-point {
  background-color: var(--theme-default);
}
.card {
  > .list-group {
    + .card-footer {
      border-top: 1px solid $light-gray;
    }
  }
}
// sticky
.note {
  background-color: rgba($primary-color, 0.3);
  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}
.react-stickies-wrapper {
  .btn-close {
    &:before,
    &:after {
      background: $transparent-color !important;
    }
  }
  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }
      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }
      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }
      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }
      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }
      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}
// sweetalert
.lead {
  margin-bottom: 5px;
}
.swal2-container {
  .swal2-actions {
    .swal2-styled {
      font-size: 14px;
    }
  }
}
.swal2-styled {
  &.swal2-confirm {
    background-color: var(--theme-default) !important;
    &:focus {
      box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
    }
  }
}
.swal2-title {
  font-size: 20px !important;
}
.swal2-progress-steps {
  .swal2-progress-step,
  .swal2-progress-step-line {
    background: var(--theme-default) !important;
  }
  .swal2-progress-step {
    &.swal2-active-progress-step {
      background: var(--theme-default) !important;
      ~ .swal2-progress-step,
      ~ .swal2-progress-step-line {
        background: rgba($primary-color, 0.2) !important;
      }
    }
  }
}
// datetime picker
.bootstrap-datetimepicker-widget {
  tr {
    th,
    td {
      &:active,
      &.active {
        background-color: var(--theme-default);
      }

      span {
        &:active,
        &.active {
          background-color: var(--theme-default);
        }
      }
    }
  }
}
// upload
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  background: $transparent-color !important;
  .uploadPictureContainer {
    margin: 1% !important;
  }
  .chooseFileButton {
    margin-top: 0 !important;
  }
}
// daterange picker
.daterangepicker {
  td,
  th {
    &.available {
      &:hover {
        background-color: var(--theme-default);
      }
    }
  }
}
// to-do
.todo {
  .action-box {
    &.large {
      .icon {
        .icon-trash,
        .icon-check {
          color: var(--theme-default);
        }
      }
    }
  }
  .todo-list-wrapper {
    #todo-list {
      li {
        &.completed {
          .task-container {
            .task-label {
              color: var(--theme-default);
            }
          }
        }
      }
    }
  }
  @keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }

    100% {
      background: $white;
    }
  }
}
// scrollable page
.scrollarea {
  max-width: 800px;
  max-height: 300px;
  .scrollbar-container {
    z-index: 7 !important;
  }
}
.horz-scroll-content {
  width: 1600px;
}