div.timeline-responsive {
  padding: 4px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

ul {
  padding-inline-start: 0px !important;
}

.timeline {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
}

.li {
  transition: all 200ms ease-in;
}

.timestamp {
  margin-bottom: 20px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status {
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #d6dce0;
  position: relative;
  transition: all 200ms ease-in;
}

.status h5 {
  font-weight: 600;
  margin-top: 20px;
  white-space: normal;
  width: 200px;
  word-break: break-word;
  text-align: center;
  cursor: default;
  margin-bottom: 0;
}

.status:before {
  content: '';
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #22baa0;
}

.li.complete .status:before,
.label-complete {
  background-color: #22baa0;
  border: none;
  transition: all 200ms ease-in;
}

.li.complete .status h5 {
  color: #22baa0;
}

.li.actual .status {
  border-top: 2px solid #f2ca4c;
}

.li.actual .status:before,
.label-actual {
  background-color: #f2ca4c;
  border: none;
  transition: all 200ms ease-in;
}

.li.actual .status h5 {
  color: #f2ca4c;
}

.label-pending {
  background-color: #777;
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    display: block;
  }

  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }

  .timestamp {
    width: 100px;
  }

  .status:before {
    left: -8%;
    top: 30%;
    transition: all 200ms ease-in;
  }
}

div.timeline-responsive::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

div.timeline-responsive::-webkit-scrollbar-track {
  background: #fbfbfb;
}

div.timeline-responsive::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 15px;
}
