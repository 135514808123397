// *** utils ***
@import './utils/_variables.scss';

:root {
  --theme-default: #534686;
  --theme-secondary: #ffa47a;
}
// *** base ***
@import './base/_reset.scss';
@import './base/_typography.scss';
//  *** components ***
@import './components/_alert.scss';
@import './components/_buttons.scss';
@import './components/_card.scss';
@import './components/_list.scss';
@import './components/_loader.scss';
@import './components/_scrollbar.scss';
@import './components/react-plugin';
@import './components/_tab.scss';
@import './components/_table.scss';
@import './components/_icons.scss';
@import './components/_select2.scss';
@import './components/_typeahead-search.scss';
//	*** pages ***
@import './pages/_dashboard_default.scss';
@import './pages/_dashboard_2.scss';
@import './pages/_ecommerce.scss';
@import './pages/_login.scss';
// *** layout ***
@import './layout/_footer.scss';
@import './layout/_grid.scss';
@import './layout/_header.scss';
@import './layout/_navs.scss';
@import './layout/_sidebar.scss';
@import './layout/_box-layout.scss';
/* ---------------------
	*** themes ***
-----------------------*/
@import './themes/_authLogin.scss';
@import './themes/_dark.scss';
@import './themes/_rtl.scss';
@import './themes/_theme-customizer.scss';
@import './themes/_update.scss';
